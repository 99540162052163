<template>
  <!-- 组件思路，将上传和删除文件的操作暂存在excutePrepare中，等待父组件主动调用 handleSave方法再依次执行操作-->
  <div v-if="!draggable">
    <a-upload
      :custom-request="handleUpload"
      :fileList="showFileList"
      :remove="handleRemove"
      @preview="download"
      :disabled="!able"
      :showUploadList="!preview"
    >
      <a-button type="link" :disabled="!able">
        <span v-if="description !== ''">{{ description }}</span>
        <a-icon type="upload" />
      </a-button>
    </a-upload>
    <upload-list-preview
      :fileList="showChildFileList"
      v-if="preview"
    ></upload-list-preview>
  </div>
  <div v-else>
    <a-upload-dragger
      :custom-request="handleUpload"
      :fileList="showFileList"
      :showUploadList="!preview"
      :remove="handleRemove"
      @preview="download"
      :disabled="!able"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        {{ description || "单击或拖动文件到此区域以上传" }}
      </p>
    </a-upload-dragger>
    <upload-list-preview
      :fileList="showChildFileList"
      v-if="preview"
    ></upload-list-preview>
  </div>
</template>

<script>
import {
  getUploadAccess,
  getOssDownloadURL,
  deleteOssFile,
  getFileList,
  getFilePreview,
  getFileListBatchByGroup,
} from "@/api/file";
import UploadListPreview from "./UploadListPreview.vue";
import { getFileDir, getExtensionsFromFileName } from "@/utils/upload-utils";
import { uuid } from "@/utils/uuid";
import axios from "axios";

export default {
  name: "UploadFileToOss",
  components: {
    UploadListPreview,
  },
  props: {
    type: {
      //文件类型
      type: String,
      require: true,
    },
    relateId: {
      type: Number,
      default: null,
    },
    description: {
      type: String,
      default: "",
    },
    followIds: {
      //用来判断是否为用户的项目或者用户项目所属公司
      type: Array,
      default: () => {
        return [];
      },
    },
    draggable: {
      //是否使用可拖拽样式上传
      type: Boolean,
      default: false,
    },
    maxQuantity: {
      //最大上传文件数目
      type: Number,
    },
    limitType: {
      //上传文件格式限制
      type: Array,
    },
    noLimit: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [], //数据库中真正的file List
      showFileList: [], //显示出来的file List，如果不点保存，则与数据库中的对应不上
      excutePrepare: [], //异步暂存操作，等待父组件调用保存后进行文件上传删除操作
      showChildFileList: [], //包含预览图的,传递给UploadListPreview（预览）组件的file List,
      childFileList: [], //子组件的文件列表
      uploadId: null, //上传文件的relate_id
      // deadIds: JSON.parse(JSON.stringify(this.fileIds)),
      Files: new FormData(),
      signature: {
        accessId: "",
        signature: "",
        host: "",
        policy: "",
        callback: "",
        expire: "",
        dir: "",
      },
    };
  },
  computed: {
    able() {
      if (this.noLimit) return true;
      if (
        this.followIds.length != 0
          ? this.hasAction("upload-file") &&
            this.operateFollowCompany(this.followIds) &&
            this.editable
          : this.hasAction("upload-file") && this.editable
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.fileList = [];
      this.showFileList = [];
      this.childFileList = []
      if (this.relateId != null) {
        if (this.type.toUpperCase() == "VPN_QRCODE") {
          getFileListBatchByGroup({
            fileRelateVos: [
              {
                relateId: 0,
                relateType: "VPN_QRCODE",
              },
            ],
            groupId: 0,
          }).then((res) => {
            let file = res.data.at(-1);
            if (file) {
              this.fileList.push({
                uid: file.id,
                name: file.name,
                status: "done",
                url: file.path,
              });
              this.childFileList.push({
                  uid: file.id,
                  name: file.name,
                  status: "done",
                  url: file.path,
                  type: file.type,
                  preview: "/",
                  creatorId: file.creatorId,
                });
            }
            //获取预览
            this.showFileList = JSON.parse(JSON.stringify(this.fileList));
            let _showChildFileList = JSON.parse(
              JSON.stringify(this.childFileList)
            );
            _showChildFileList.forEach((item, index) => {
              if (
                item.type == "image/png" ||
                item.type == "image/jpg" ||
                item.type == "image/jpeg"
              ) {
                getFilePreview({
                  fileId: item.uid,
                  protocol: window.location.protocol,
                }).then((res) => {
                  this.$set(
                    _showChildFileList[index],
                    "preview",
                    res.data[0]?.url
                  );
                });
              }
            });
            this.showChildFileList = _showChildFileList;
          });
        } else {
          getFileList({
            relateId: this.relateId,
            relateType: this.type.toUpperCase(),
          }).then((res) => {
            res.data.forEach((item) => {
              if (item) {
                this.fileList.push({
                  uid: item.id,
                  name: item.name,
                  status: "done",
                  url: item.path,
                });
                this.childFileList.push({
                  uid: item.id,
                  name: item.name,
                  status: "done",
                  url: item.path,
                  type: item.type,
                  preview: "/",
                  creatorId: item.creatorId,
                });
              }
            });
            //获取预览
            this.showFileList = JSON.parse(JSON.stringify(this.fileList));
            let _showChildFileList = JSON.parse(
              JSON.stringify(this.childFileList)
            );
            _showChildFileList.forEach((item, index) => {
              if (
                item.type == "image/png" ||
                item.type == "image/jpg" ||
                item.type == "image/jpeg"
              ) {
                getFilePreview({
                  fileId: item.uid,
                  protocol: window.location.protocol,
                }).then((res) => {
                  this.$set(
                    _showChildFileList[index],
                    "preview",
                    res.data[0]?.url
                  );
                });
              }
            });
            this.showChildFileList = _showChildFileList;
          });
        }
      }
    },
    handleUpload(files) {
      if (
        this.limitType &&
        !this.limitType.includes(getExtensionsFromFileName(files.file.name))
      ) {
        this.$message.error(
          `上传格式错误，仅支持${this.limitType.join("、")}格式`
        );
        return;
      }
      if (this.maxQuantity && this.showFileList.length >= this.maxQuantity) {
        //如果设置了最大上传数目并且文件数量已经超过该数目
        this.$message.error(`只允许上传${this.maxQuantity}个文件，请先删除一些已有文件`)
        return false;
      }
      this.excutePrepare.push({
        action: "upload",
        file: files,
        id: files.file.uid,
      });
      this.showFileList.push({
        uid: files.file.uid,
        name: files.file.name,
        status: "done",
        url: "/",
      });
      files.file.status = "done";
      files.file.url = "/";
      this.showChildFileList.push(files.file);
    },
    uploadFile(files) {
      this.Files = new FormData();
      const file = files.file;
      // debugger
      let fileName = `${getFileDir(
        this.type
      )}/${uuid().toLowerCase()}.${getExtensionsFromFileName(file.name)}`;
      let now = Date.parse(new Date()) / 1000;
      if (
        this.signature.expire === "" ||
        parseInt(this.signature.expire) < now + 3
      ) {
        // 判断签名是否到期
        return getUploadAccess({
          urlPrefix: `${window.location.protocol}//`,
        }).then((res) => {
          // debugger
          if (res.code === 0) {
            this.signature = res.data;
            this.setFileValue(fileName, file);
            return this.fileUpload(this.signature.host, file.name);
          }
        });
      } else {
        this.setFileValue(fileName, file);
        return this.fileUpload(this.signature.host, file.name);
      }
    },
    async fileUpload(url, fileOriginName) {
      let res = await axios
        .post(url, this.Files, {
          //发送请求的url就是服务器返回的host
          "Content-Type": "multipart/form-data",
          withCredentials: false,
          timeout: 3 * 60 * 1000, // 设置超时时间3分钟
        })
        .catch((err) => {
          if (err.toString().split(" ").includes("timeout")) {
            this.$notification.error({
              message: `${fileOriginName}上传失败`,
              description: "上传超时，请检查您的网络情况，稍后重新上传",
            });
          } else {
            this.$notification.error({
              message: `${fileOriginName}上传失败`,
              description: err.toString(),
            });
          }
        });
      // debugger
      if (res != null) {
        if (res.data.Status === "OK") {
          this.fileList.push({
            uid: res.data.id,
            name: fileOriginName,
            status: "done",
            url: "/",
          });
          this.$emit("upload-file-to-Oss", res.data.id);
        } else {
          this.$notification.error({
            message: `${fileOriginName}上传失败`,
            description: `上传校验不通过`,
          });
        }
      }
    },
    setFileValue(fileName, file) {
      this.Files.set("key", `${this.signature.dir}${fileName}`); //key 唯一值  即相对路径
      this.Files.set("callback", this.signature.callback); // callback不能放在file属性下面，会被作为file的一部分
      this.Files.set("policy", this.signature.policy); //服务器返回的policy
      this.Files.set("OSSAccessKeyId", this.signature.accessId); //服务器返回的accessId
      this.Files.set("success_action_status", "200"); //定义成功为200
      this.Files.set("signature", this.signature.signature); //服务器返回的signature
      this.Files.set("name", fileName); //上传到oss的文件名
      this.Files.set(
        "Content-Disposition",
        'attachment;filename="' + file.name + '"'
      ); //设置下载时的文件名
      this.Files.set("x:old_name", file.name); //自定义参数，携带文件原本名字
      this.Files.set("x:relate_type", this.type.toUpperCase()); //自定义参数，携带文件类型
      this.Files.set("x:relate_id", this.uploadId); //自定义参数，携带上传id
      this.Files.set("x:upload_time", new Date().getTime()); //自定义参数，携带上传时间
      //file参数要放到最下面，否则其他参数会被认为是file的一部分
      this.Files.set("file", file, fileName); //文件对象
    },
    download(files) {
      if (this.hasAction("download-file") || this.noLimit) {
        //如果文件url为‘/’，说明文件还未上传到oss，不提供下载
        if (files.url === "/") {
          this.$message.info(
            "文件还未上传至服务器，需保存本页内容后才能进行下载"
          );
          return;
        }
        getOssDownloadURL({
          fileId: files.uid,
          protocol: window.location.protocol,
        }).then((res) => {
          window.open(res.data, "_blank");
        });
      } else {
        this.$message.error("无下载权限！");
      }
    },
    handleRemove(files) {
      if (
        (this.followIds.length != 0
          ? this.hasAction("delete-file") &&
            this.operateFollowCompany(this.followIds)
          : this.hasAction("delete-file")) ||
        this.noLimit
      ) {
        if (!this.editable) return;
        let id = files.uid;
        this.showFileList = this.showFileList.filter((item) => item.uid !== id);
        this.showChildFileList = this.showChildFileList.filter(
          (item) => item.uid !== id
        );
        if (this.excutePrepare.filter((item) => item.id === id).length > 0) {
          //如果在暂存数组中找到了文件，说明是删除用户刚上传的新文件，则直接在暂存数组中删除
          this.excutePrepare = this.excutePrepare.filter(
            (item) => item.id !== id
          );
        } else {
          //说明用户要删除已上传至oss的文件，存入暂存数组中
          this.excutePrepare.push({
            action: "remove",
            id: id,
          });
        }
      } else {
        this.$message.error("无删除权限！");
      }
    },
    removeFile(id) {
      return deleteOssFile({
        fileId: id,
        relateType: this.type.toUpperCase(),
      }).then(() => {
        // debugger
        this.fileList = this.fileList.filter((item) => item.uid != id);
        this.$emit("delete-Oss-file", id);
      });
    },
    //供父组件调用，需提供relate_id
    handleSave(relateId) {
      // debugger
      this.uploadId = relateId;
      let promiseArr = [];
      while (this.excutePrepare.length > 0) {
        let item = this.excutePrepare.shift();
        if (item.action === "upload") {
          promiseArr.push(this.uploadFile(item.file));
        } else if (item.action === "remove") {
          promiseArr.push(this.removeFile(item.id));
        }
      }
      // this.excutePrepare.forEach(async (item) => {
      //   if (item.action === 'upload') {
      //     promiseArr.push(this.uploadFile(item.file))
      //   } else if (item.action === 'remove') {
      //     promiseArr.push(this.removeFile(item.id))
      //   }
      // })
      return this.orderPromise(promiseArr).finally(() => {
        this.loadData();
      });
    },
    //将promise请求排序调用
    orderPromise(arr) {
      // debugger
      let res = Promise.resolve();
      arr.forEach((item) => {
        res = res.then(() => {
          return item;
        });
      });
      return res;
    },
  },
};
</script>
