<template>
  <!-- 组件思路: 对于已上传的文件, 预览图在父组件拿到, 对于未上传的文件, 预览图通过转换base64拿到 -->
  <div>
    <div class="upload-list-preview">
      <div class="upload-list-preview-item" v-for="item in fileList" :key="item.uid">
        <div class="upload-list-preview-item-thumbnail">
          <div
            v-if="item.iconType === 'image'"
            class="thumbnail-wrapp-pic"
            :style="{ backgroundImage: 'url(' + item.preview + ')' }"
            @click="handlePreview(item.uid)"
          ></div>
          <div v-else>
            <a-icon
              :type="iconStyle[item.iconType].type"
              :style="{
                color: iconStyle[item.iconType].color,
              }"
            />
          </div>
        </div>
        <div class="upload-list-preview-item-right">
          <div class="upload-list-preview-item-right-name" @click="handleDownLoad(item)">
            {{ item.name.slice(0, 20) + (item.name.length > 20 ? '...' : '') }}
          </div>
          <div class="upload-list-preview-item-right-icon">
            <a-icon type="delete" @click="handleDel(item)" />
          </div>
        </div>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="modalCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'UploadListPreview',
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const iconStyle = {
      docx: {
        type: 'file-word',
        color: '#2a5699',
      },
      doc: {
        type: 'file-word',
        color: '#2a5699',
      },
      xls: {
        type: 'file-excel',
        color: '#1e7144',
      },
      xlsx: {
        type: 'file-excel',
        color: '#1e7144',
      },
      txt: {
        type: 'file-text',
        color: '#b1b1b1',
      },
      rtf: {
        type: 'file-text',
        color: '#b1b1b1',
      },
      ppt: {
        type: 'file-ppt',
        color: '#d24625',
      },
      pptx: {
        type: 'file-ppt',
        color: '#d24625',
      },
      pdf: {
        type: 'file-pdf',
        color: '#a33639',
      },
      zip: {
        type: 'file-zip',
        color: '#a2a6ea',
      },
      rar: {
        type: 'file-zip',
        color: '#a2a6ea',
      },
      jar: {
        type: 'file-zip',
        color: '#a2a6ea',
      },
      else: {
        type: 'file',
        color: '#2982b7',
      },
    }
    return {
      iconStyle,
      previewVisible: false,
      previewImage: '',
    }
  },
  created() {},
  watch: {
    fileList: {
      handler() {
        this.loadList()
      },
      deep: true,
    },
  },
  methods: {
    loadList() {
      this.fileList.map(item => {
        this.getPreview(item)
      })
    },
    getFilesType(name) {
      let type = name.substring(name.lastIndexOf('.') + 1)
      return type
    },
    handleDel(files) {
      this.$parent.handleRemove(files)
    },
    handleDownLoad(files) {
      this.$parent.download(files)
    },
    handlePreview(uid) {
      let preItem = this.fileList.find(item => item.uid === uid)
      this.previewImage = preItem.preview
      this.previewVisible = true
    },
    async getPreview(file) {
      let fileTYpe = this.getFilesType(file.name)
      if (fileTYpe === 'png' || fileTYpe === 'jpg' || fileTYpe === 'jpeg') {
        // 图片文件
        file.iconType = 'image'
        if (file?.url === '/' && !file.preview) {
          //未上传的
          file.preview = await this.getBase64(file)
        } else {
          //已上传的
          // file.preview = file.preview
        }
      } else {
        //不是图片文件
        let iconArr = Object.keys(this.iconStyle).filter(item => item != 'else')
        iconArr.findIndex(item => item == fileTYpe) === -1 ? (file.iconType = 'else') : (file.iconType = fileTYpe)
        file.preview = '/'
      }
      this.$forceUpdate()
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    modalCancel() {
      this.previewVisible = false
    },
  },
}
</script>
<style lang="less" scoped>
.upload-list-preview {
  margin: 10px 0;
  display: flex;
  width: 1110px;
  flex-wrap: wrap;
  /deep/.upload-list-preview-item {
    display: flex;
    align-items: center;
    width: 250px;
    height: 70px;
    padding: 5px;
    margin: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: border-color 0.3s ease;

    .upload-list-preview-item-thumbnail {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      .thumbnail-wrapp-pic {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
      }

      .anticon {
        font-size: 35px;
      }
    }
    .upload-list-preview-item-right {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;
      .upload-list-preview-item-right-name {
        color: #1f70b1;
        line-height: 20px !important;
      }
      .upload-list-preview-item-right-icon {
        color: #bababa;
      }
    }
  }
}

.upload-list-preview-item:hover:not(.is-disabled) {
  cursor: pointer;
  border: 1px solid #1f70b1;
}

/deep/.ant-modal {
  top: 35%;
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }
  }
}
</style>
